/* Windows & Mac specific font */
body.windows,
body.mac {
  font-family: "Roboto", Arial, sans-serif;
}

table {
  margin: 0.75rem 0;
}

table,
th,
td {
  border: 0.0625rem solid rgba(224, 224, 224, 1);
  border-collapse: collapse;
}

table {
  border-radius: 1.5rem;
  overflow-y: auto;
}

th,
td {
  padding: 0.625rem 1.25rem;
}

.nav-tool-mobile {
  display: flex;
  flex-direction: row;
  width: 50%;
  justify-content: space-between;
  align-items: center;
}

.typing-demo {
  overflow: hidden;
  border-right: 0.15em solid orange;
  white-space: nowrap;
  margin: 0 auto;
  letter-spacing: 0.15em;
  animation: typing 3.5s steps(40, end), blink-caret 0.75s step-end infinite;
}

@keyframes typing {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }

  50% {
    border-color: orange;
  }
}

strong {
  font-weight: 600 !important;
}

/* recharts style */
.recharts-text {
  font-size: 0.6875rem !important;
}
.recharts-legend-item-text {
  font-size: 0.75rem !important;
}

::placeholder,
textarea {
  font-family: "Roboto", Arial, sans-serif;
}
